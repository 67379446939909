import { createHashHistory } from 'history';
import { t } from 'i18next';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Route as HashRoute, HashRouter, Switch } from 'react-router-dom';

import authProvider from './authProvider';
import createAdminStore from './createAdminStore';
import CustomLayout from './CustomLayout';
import dataProvider from './dataProvider';

import { DefectiveMaterialEdit } from './components/DefectiveMaterials';
import { LocationList, LocationShow } from './components/Locations';
import { MaintenanceList, MaintenanceShow } from './components/Maintenance';
import { MaintenanceMaterialEdit } from './components/MaintenanceMaterials';
import { MaterialList, MaterialShow } from './components/Materials';
import { MovementList, MovementShow } from './components/Movements';
import { Profile } from './components/Profile';
import MyLoginPage from './components/react-admin-components/LoginPage';
import { NewPasswordForm, ResetPasswordForm } from './components/ResetPassword';
import { UserList, UserShow } from './components/Users';
import './style/index.scss';

const customRoutes = [<Route exact path='/profile' component={Profile} icon='assets/user-icon.svg' />];

const messages = { fr: frenchMessages };
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'fr');

const App = () => {
    const history = createHashHistory();

    return (
        <HashRouter>
            <Switch>
                <HashRoute path='/reset-password'>
                    <Provider store={createAdminStore({ authProvider, dataProvider, history })}>
                        <ResetPasswordForm />
                    </Provider>
                </HashRoute>
                <HashRoute path='/set-new-password'>
                    <Provider store={createAdminStore({ authProvider, dataProvider, history })}>
                        <NewPasswordForm />
                    </Provider>
                </HashRoute>
                <Admin
                    basename='/admin'
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    customRoutes={customRoutes}
                    i18nProvider={i18nProvider}
                    layout={CustomLayout}
                    loginPage={MyLoginPage}
                    history={history}
                >
                    {permissions => [
                        <Resource
                            name='materials'
                            list={MaterialList}
                            show={MaterialShow}
                            options={{ label: t('layout.materials') }}
                            icon='assets/material-icon.svg'
                        />,
                        <Resource
                            name='locations'
                            list={LocationList}
                            show={LocationShow}
                            options={{ label: t('layout.locations') }}
                            icon='assets/location-icon.svg'
                        />,
                        <Resource
                            name='movements'
                            list={MovementList}
                            show={MovementShow}
                            options={{ label: t('layout.movements') }}
                            icon='assets/movement-icon.svg'
                        />,
                        <Resource
                            name='maintenance'
                            list={MaintenanceList}
                            options={{
                                label: t('layout.maintenance'),
                                children: ['defectiveMaterials', 'maintenanceMaterials']
                            }}
                            icon='assets/defective-material-icon.svg'
                        />,
                        <Resource name='defectiveMaterials' show={MaintenanceShow} edit={DefectiveMaterialEdit} />,
                        <Resource name='maintenanceMaterials' show={MaintenanceShow} edit={MaintenanceMaterialEdit} />,
                        <Resource
                            name='users'
                            list={UserList}
                            show={UserShow}
                            options={{ label: t('layout.users') }}
                            icon='assets/team-icon.svg'
                        />,
                        <Resource name='plants' />,
                        <Resource name='locationmaterials' />,
                        <Resource name='materialCategories' />,
                        <Resource name='defectiveMaterials/maintenance/list' />
                    ]}
                </Admin>
            </Switch>
        </HashRouter>
    );
};

export default App;
