export const LOCATION_TYPES = { list_choices: ["LOCAL", "CTR"], display: ["LOCAL", "CTR", "ZM"] };
export const FILLING_LEVEL = { list_choices: ["UNUSABLE", "EMPTY", "ONE_QUARTER", "HALF", "THREE_QUARTER", "FULL"], display: ["UNUSABLE", "NA", "EMPTY", "ONE_QUARTER", "HALF", "THREE_QUARTER", "FULL"] };
export const MATERIAL_CATEGORIES = ["LAYOUT", "FME", "CLEANING", "LEAD_FILLING", "STORAGE"];
export const ROLES = {
    ROLE_SUPER_ADMIN: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_MANAGER", "ROLE_AGENT"],
    ROLE_ADMIN: ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_AGENT"],
    ROLE_MANAGER: ["ROLE_MANAGER", "ROLE_AGENT"]
};
export const RANGE_LIMITS = [10, 50, 100];
export const DEFECTIVE_MATERIAL_STATES = ["TO_PROCESS", "MAINTENANCE", "REPAIRED", "DISCARDED"];
export const DEFECTIVE_MATERIAL_ANOMALY_TYPES = ['MECHANIC', 'HYDRAULIC', 'ELECTRIC', 'PNEUMATIC', 'LIFTING', 'METROLOGY', 'OTHER'];