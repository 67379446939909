import Downshift from 'downshift';
import { t } from 'i18next';
import { useState } from 'react';
import {
    AutocompleteInput,
    Create,
    Edit,
    FileField,
    FileInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    useGetList,
    useNotify,
    useRecordContext,
    useRefresh
} from 'react-admin';
import { useForm } from 'react-final-form';
import { DEFECTIVE_MATERIAL_ANOMALY_TYPES, DEFECTIVE_MATERIAL_STATES } from '../constants';
import '../style/components/defective-materials.scss';
import { arrayToChoices } from '../utils';
import { MySaveButton } from './react-admin-components/Buttons';
import { MyTextInput } from './react-admin-components/Inputs';

const MaterialInput = props => {
    const record = useRecordContext(props);
    return <MyTextInput {...props} source={record.rawMaterial ? 'rawMaterial' : 'material.name'} />;
};

const OriginInput = props => {
    const record = useRecordContext(props);
    return <MyTextInput {...props} source={record.rawOrigin ? 'rawOrigin' : 'origin.name'} />;
};

export const MaintenanceMaterialEdit = props => {
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <Edit
            {...props}
            className='create-form'
            mutationMode='optimistic'
            transform={data => ({ id: data.id, state: data.state })}
            onSuccess={() => {
                props.closeDialog();
                refresh();
                notify(t('maintenance.updated'), { undoable: false });
            }}
            onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
        >
            <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />}>
                <MaterialInput label={t('defective-materials.material-reference')} labelUnderInput disabled />
                <OriginInput label={t('defective-materials.material-location')} labelUnderInput disabled />
                <SelectInput
                    source='state'
                    choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
                    validate={required()}
                    label={t('defective-materials.state')}
                    className='input select label-under-input'
                />
            </SimpleForm>
        </Edit>
    );
};

export const MaintenanceMaterialCreate = props => {
    const refresh = useRefresh();
    const notify = useNotify();

    return (
        <>
            <small>{t('defective-materials.specific-anomaly-alert')}</small>
            <Create
                {...props}
                className='create-form'
                transform={data => {
                    const body = {
                        anomalyType: data.anomalyType,
                        comment: data.comment,
                        [data.isCustomMaterial ? 'rawMaterial' : 'materialId']: data.material,
                        [data.isCustomLocation ? 'rawOrigin' : 'originId']: data.origin,
                        image: data.image
                    };

                    let formData = new FormData();
                    Object.keys(body).forEach(key => {
                        formData.append(key, key === 'image' ? body[key]?.rawFile : body[key]);
                    });

                    return { formData };
                }}
                onSuccess={() => {
                    props.closeDialog();
                    refresh();
                    notify(t('defective-materials.created'), { undoable: false });
                }}
                onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
            >
                <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />}>
                    <MaterialAutocompleteInput />
                    <LocationAutocompleteInput />
                    <SelectInput
                        source='anomalyType'
                        className='input select label-under-input'
                        choices={arrayToChoices(DEFECTIVE_MATERIAL_ANOMALY_TYPES, 'defective-materials')}
                        validate={required()}
                        label={t('defective-materials.anomaly-type')}
                    />
                    <FileInput source='image' accept='image/*,.pdf' label=''>
                        <FileField source='src' title='rawFile.path' target='_blank' />
                    </FileInput>
                    <MyTextInput
                        source='comment'
                        multiline
                        labelUnderInput
                        margin='normal'
                        minRows={4}
                        maxRows={10}
                        label={t('defective-materials.comment-title')}
                        validate={required()}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

const MaterialAutocompleteInput = () => {
    const [materialSearch, setMaterialSearch] = useState();
    const [customMaterial, setCustomMaterial] = useState();
    const materials = useGetList(
        'materials',
        { page: 1, perPage: 10 },
        { field: 'name', order: 'ASC' },
        materialSearch ? { searchFields: { name: `%${materialSearch}%` } } : {}
    );

    const form = useForm();
    form.change('isCustomMaterial', !!customMaterial);

    const materialChoices = [
        ...(customMaterial ? [{ id: customMaterial, name: customMaterial, custom: true }] : []),
        ...materials.ids.map(id => {
            return { id: id, name: materials.data[id].name };
        })
    ];

    return (
        <>
            <AutocompleteInput
                source='material'
                choices={materialChoices}
                className='input auto-complete label-under-input'
                validate={required()}
                resettable
                clearAlwaysVisible
                label={t('defective-materials.material-reference')}
                createLabel={t('defective-materials.select-material-custom')}
                createItemLabel={t('defective-materials.select-custom-item')}
                onUserAction={action => {
                    if (action.type === Downshift.stateChangeTypes.changeInput && action.inputValue) {
                        setMaterialSearch(action.inputValue);
                    } else if (
                        action.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem &&
                        action.inputValue === undefined
                    ) {
                        setMaterialSearch();
                    }
                }}
                onSelect={record => !record.custom && setCustomMaterial()}
                onCreate={name => {
                    setCustomMaterial(name);
                    return { id: name, name };
                }}
                matchSuggestion={() => true}
            />
            <TextInput source='isCustomMaterial' style={{ display: 'none' }} />
        </>
    );
};

const LocationAutocompleteInput = () => {
    const [locationSearch, setLocationSearch] = useState();
    const [customLocation, setCustomLocation] = useState();
    const locations = useGetList(
        'locations',
        { page: 1, perPage: 10 },
        { field: 'name', order: 'ASC' },
        locationSearch ? { searchFields: { name: `%${locationSearch}%` } } : {}
    );

    const form = useForm();
    form.change('isCustomLocation', !!customLocation);

    const locationChoices = [
        ...(customLocation ? [{ id: customLocation, name: customLocation }] : []),
        ...locations.ids.map(id => {
            return { id: id, name: locations.data[id].name };
        })
    ];

    return (
        <>
            <AutocompleteInput
                source='origin'
                choices={locationChoices}
                className='input auto-complete label-under-input'
                validate={required()}
                resettable
                clearAlwaysVisible
                label={t('defective-materials.material-location')}
                createLabel={t('defective-materials.select-location-custom')}
                createItemLabel={t('defective-materials.select-custom-item')}
                onUserAction={action => {
                    if (action.type === Downshift.stateChangeTypes.changeInput && action.inputValue) {
                        setLocationSearch(action.inputValue);
                    } else if (
                        action.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem &&
                        action.inputValue === undefined
                    ) {
                        setLocationSearch();
                    }
                }}
                onSelect={record => !record.custom && setCustomLocation()}
                onCreate={name => {
                    setCustomLocation(name);
                    return { id: name, name };
                }}
                matchSuggestion={() => true}
            />
            <TextInput source='isCustomLocation' style={{ display: 'none' }} />
        </>
    );
};
