import DefaultIcon from '@material-ui/icons/ViewList';
import { t } from 'i18next';
import { AppBar, Layout, Menu, MenuItemLink, Sidebar, getResources, useLogout } from 'react-admin';
import { useSelector } from 'react-redux';
import './style/components/react-admin-components/custom-layout.scss';

const CustomMenu = props => {
    const resources = useSelector(getResources);
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    return (
        <Menu {...props}>
            {resources.map(resource => {
                if (resource.hasList) {
                    return (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={(resource.options && resource.options.label) || resource.name}
                            leftIcon={
                                resource.icon ? (
                                    <img src={resource.icon} alt={t('layout.menu-icon')} />
                                ) : (
                                    <DefaultIcon />
                                )
                            }
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                            isActive={
                                resource.options?.children
                                    ? (match, location) =>
                                          match ??
                                          resource.options.children.some(child => location.pathname.includes(child))
                                    : false
                            }
                        />
                    );
                }
                return null;
            })}
            <MenuItemLink
                to='/profile'
                primaryText={t('layout.user')}
                leftIcon={<img src='assets/user-icon.svg' alt={t('layout.menu-icon')} />}
            />
        </Menu>
    );
};

const MySidebar = props => {
    const logout = useLogout();
    return (
        <div className='my-sidebar'>
            <div>
                <div className='menu-header'>
                    <img
                        className='menu-logo'
                        src={process.env.REACT_APP_SECONDARY_LOGO}
                        alt={t('layout.elio-secondary-logo')}
                    />
                </div>
                <Sidebar {...props} />
            </div>
            <div className='logout-container'>
                <button onClick={() => logout()}>
                    <img src='assets/logout-icon.svg' alt='logout' />
                </button>
            </div>
        </div>
    );
};

const MyAppBar = props => <AppBar {...props} />;

const CustomLayout = props => (
    <Layout {...props} menu={CustomMenu} appBar={MyAppBar} sidebar={MySidebar} className='my-layout' />
);

export default CustomLayout;
